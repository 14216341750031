import React from "react";
import styled, { DefaultTheme } from "styled-components";
import { Container } from "react-bootstrap";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import { Box, Text } from "../../components/Core";
import { device } from "../../utils/breakpoints";
import data from '../../../site/content/index.json'


// @ts-expect-error false positive image import failure
import imgL1Brand1 from "../../assets/image/clients/bromley.png";
// @ts-expect-error false positive image import failure
import imgL1Brand2 from "../../assets/image/clients/cambac.png";
// @ts-expect-error false positive image import failure
import imgL1Brand3 from "../../assets/image/clients/debenhams.png";
// @ts-expect-error false positive image import failure
import imgL1Brand4 from "../../assets/image/clients/kingsgate.png";
// @ts-expect-error false positive image import failure
import imgL1Brand5 from "../../assets/image/clients/southend.png";

interface ThemeProps {
  theme: DefaultTheme;
}

const TitleContainer = styled(Box)<ThemeProps>`
  position: relative;
  &:after {
    content: "";
    height: 1px;
    position: absolute;
    right: 0;
    top: 50%;
    width: 32%;
    background: ${({ theme }) => theme.colors.border};
    margin-top: 0.5px;
    display: none;

    @media ${device.md} {
      width: 40%;
      display: block;
    }
    @media ${device.lg} {
      width: 52%;
    }
    @media ${device.xl} {
      width: 60%;
    }
  }
`;

const Brand = styled(Box)`
  opacity: 0.7;
  transition: all 0.3s ease-out;
  &:hover {
    opacity: 1;
  }
`;

const Clients = () => (
  <>
    {/* <!-- Clients Brands Area --> */}
    <Box pb={"30px"} mt={"-15px"}>
      <Container>
        <TitleContainer mb={"40px"}>
          <Text fontSize="18px" as="h4" className="">
            {data.clients.text}
          </Text>
        </TitleContainer>
      </Container>
      <Container>
        <Box
          className="d-flex justify-content-center justify-content-lg-between
         align-items-center flex-wrap"
          mx="-32px"
        >
          <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={125}
            visibleSlides={5}
            totalSlides={data.clients.images.length}
            // @ts-ignore (todo)
            style={{ width: '100%', height: '100px' }}
            infinite
            isPlaying
            interval={2500}
          >
            <Slider>
              {data.clients.images.map(({ image, alt }, index) => (
                <Slide index={index}>
                  <img src={image} alt={alt} className="img-fluid" />
                </Slide>
              ))}
            </Slider>
          </CarouselProvider>
        </Box>
      </Container>
    </Box>
  </>
);

export default Clients;
