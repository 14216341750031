import React from "react";
import { Helmet } from "react-helmet";
import Hero from "../sections/landing1/Hero";
import Clients from "../sections/landing1/Clients";
import Feature from "../sections/landing1/Feature";
import Content1 from "../sections/landing1/Content1";
import Content2 from "../sections/landing1/Content2";
import Content3 from "../sections/landing1/Content3";
import Content4 from "../sections/landing1/Content4";

import Testimonial from "../sections/landing1/Testimonial";
import NewsTestimonial from "../sections/landing1/NewsTestimonial";

import CTA from "../sections/landing1/CTA";
import PageWrapper from "../components/PageWrapper";

const IndexPage = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Helmet>
          <title>
            ShopSafe Radio - The home of ShopWatch &amp; TownLink crime
            reduction radio systems.
          </title>
          <meta
            name="description"
            content="A leading supplier of ShopWatch &amp; TownLink radio systems, ShopSafe offer effective and affordable crime reduction radio schemes throughout the UK."
          />
        </Helmet>
        <Hero />
        <Clients />
        <Feature />
        <Content1 />
        <Content2 />
        <Content3 />
        <Content4 />
        <Testimonial />
        <NewsTestimonial />
      </PageWrapper>
    </>
  );
};
export default IndexPage;
